<template>
  <div :class="{'api-field': true, 'is-readonly': readonly, 'is-editing': editing}">
    <div class="field is-grouped">
      <p class="control">
        <input
          v-model="field.name" class="input is-small" type="text"
          placeholder="Name" :readonly="readonly && !editing"
        >
      </p>
      <p v-if="field.derived && field.definition" class="control is-expanded has-icons-right has-two-icons has-three-icons">
        <input
          v-model="field.definition.src_field" class="input is-small" type="text"
          placeholder="Field" :readonly="readonly && !editing"
        >
        <span :class="{'icon is-small is-right is-first': true, 'is-primary': field.is_number}" data-tooltip="Mark the field value as numeric">
          <svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" class="octicon"
            style="width: 100%" @click.prevent="toggleNumber"
          > <path d="M9.3 24.4h-3v-9.5l-2.9.9v-2.3L9 11.6h.3v12.8zM22.1 24.4h-8.9v-1.9l4.1-4.3c1-1.2 1.5-2.1 1.5-2.8 0-.6-.1-1-.4-1.3s-.6-.4-1-.4c-.5 0-.8.2-1.1.6s-.4.9-.4 1.4h-3c0-.8.2-1.5.6-2.2s.9-1.2 1.6-1.6 1.5-.6 2.3-.6c1.4 0 2.4.3 3.2 1s1.1 1.6 1.1 2.8c0 .5-.1 1-.3 1.5s-.5 1-.9 1.5-1 1.2-1.9 2.1l-1.7 1.9h5v2.3zM26.3 16.8h1.4c1.1 0 1.7-.5 1.7-1.6 0-.4-.1-.8-.4-1s-.6-.4-1.1-.4c-.4 0-.7.1-1 .3-.3.2-.4.5-.4.9h-3c0-.7.2-1.3.6-1.8s.9-.9 1.6-1.2c.7-.3 1.4-.4 2.2-.4 1.4 0 2.5.3 3.4 1s1.2 1.5 1.2 2.7c0 .6-.2 1.1-.5 1.6s-.8.9-1.5 1.2c.7.2 1.2.6 1.6 1.1s.6 1.1.6 1.9c0 1.1-.4 2.1-1.3 2.7s-2 1-3.5 1c-.8 0-1.6-.2-2.3-.5-.7-.3-1.3-.8-1.6-1.3s-.6-1.2-.6-1.9h3c0 .4.2.7.5 1s.7.4 1.2.4.9-.1 1.2-.4.5-.7.5-1.1c0-.6-.2-1.1-.5-1.4s-1-.6-1.5-.6h-1.4v-2.2z" /> </svg>
        </span>
        <span :class="{'icon is-small is-right is-second': true, 'is-primary': field.is_timestamp}" data-tooltip="Mark the field as a timestamp with formats">
          <svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" class="octicon"
            style="width: 100%" @click.prevent="toggleTimestamp"
          > <path d="M18 8C12.5 8 8 12.5 8 18s4.5 10 10 10 10-4.5 10-10S23.5 8 18 8zm0 18.1c-4.5 0-8.1-3.6-8.1-8.1s3.6-8.1 8.1-8.1 8.1 3.6 8.1 8.1-3.6 8.1-8.1 8.1zm2.5-4.2l-3.4-2.5c-.1-.1-.2-.2-.2-.4v-6.6c0-.3.2-.5.5-.5h1.3c.3 0 .5.2.5.5v5.7l2.7 2c.2.2.3.5.1.7l-.8 1c-.2.2-.5.2-.7.1z" />
          </svg>
        </span>
        <span :class="{'icon is-small is-right': true, 'is-primary': field.definition.is_mapping}" data-tooltip="Map the field value with a corresponding value">
          <svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" class="octicon"
            style="width: 100%" @click.prevent="toggleMapping"
          > <path d="M7 7h8v8H7zM21 21h8v8h-8z" />
            <g
              fill="none" stroke-miterlimit="10" stroke-width="2"
              style="stroke: currentColor;"
            >
              <path d="M27.1 27.1l-.7-.7" />
              <path stroke-dasharray="2.1515,2.1515" d="M24.8 24.8L10.4 10.4" />
              <path d="M9.6 9.6l-.7-.7" />
            </g>
          </svg>
        </span>
      </p>
      <p v-else class="control is-expanded has-icons-right">
        <input
          v-model="field.fixed_value" class="input is-small" type="text"
          placeholder="Value" :readonly="readonly && !editing"
        >
        <span :class="{'icon is-small is-right': true, 'is-primary': field.is_number}" data-tooltip="Mark the field value as numeric">
          <svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" class="octicon"
            style="width: 100%" @click.prevent="field.is_number = !field.is_number"
          > <path d="M9.3 24.4h-3v-9.5l-2.9.9v-2.3L9 11.6h.3v12.8zM22.1 24.4h-8.9v-1.9l4.1-4.3c1-1.2 1.5-2.1 1.5-2.8 0-.6-.1-1-.4-1.3s-.6-.4-1-.4c-.5 0-.8.2-1.1.6s-.4.9-.4 1.4h-3c0-.8.2-1.5.6-2.2s.9-1.2 1.6-1.6 1.5-.6 2.3-.6c1.4 0 2.4.3 3.2 1s1.1 1.6 1.1 2.8c0 .5-.1 1-.3 1.5s-.5 1-.9 1.5-1 1.2-1.9 2.1l-1.7 1.9h5v2.3zM26.3 16.8h1.4c1.1 0 1.7-.5 1.7-1.6 0-.4-.1-.8-.4-1s-.6-.4-1.1-.4c-.4 0-.7.1-1 .3-.3.2-.4.5-.4.9h-3c0-.7.2-1.3.6-1.8s.9-.9 1.6-1.2c.7-.3 1.4-.4 2.2-.4 1.4 0 2.5.3 3.4 1s1.2 1.5 1.2 2.7c0 .6-.2 1.1-.5 1.6s-.8.9-1.5 1.2c.7.2 1.2.6 1.6 1.1s.6 1.1.6 1.9c0 1.1-.4 2.1-1.3 2.7s-2 1-3.5 1c-.8 0-1.6-.2-2.3-.5-.7-.3-1.3-.8-1.6-1.3s-.6-1.2-.6-1.9h3c0 .4.2.7.5 1s.7.4 1.2.4.9-.1 1.2-.4.5-.7.5-1.1c0-.6-.2-1.1-.5-1.4s-1-.6-1.5-.6h-1.4v-2.2z" /> </svg>
        </span>
      </p>
      <p v-if="editing || !readonly" class="control">
        <a :class="['button is-small', field.derived ? 'is-primary' : 'is-default']" style="min-width: 3.5rem" @click.prevent="field.derived = !field.derived">{{ $t('derived') }}</a>
      </p>
      <p v-else-if="readonly" class="control">
        <a class="button is-warning is-small is-light" style="min-width: 3.5rem" @click.prevent="edit">{{ $t('edit') }}</a>
      </p>
      <p v-if="editing" class="control">
        <a class="button is-danger is-small is-light" style="min-width: 3.5rem" @click.prevent="cancel">{{ $t('cancel') }}</a>
      </p>
      <p v-else-if="readonly" class="control">
        <a class="button is-danger is-small is-light" style="min-width: 3.5rem" @click.prevent="$emit('remove')">{{ $t('remove') }}</a>
      </p>
      <p v-else class="control">
        <a class="button is-info is-small is-light" style="min-width: 3.5rem" @click.prevent="$emit('add')">{{ $t('add') }}</a>
      </p>
    </div>
    <transition name="fade">
      <div v-if="(!readonly || editing) && field.derived">
        <template v-if="field.is_timestamp">
          <label class="label is-small is-uppercase">Timestamp</label>
          <div class="field is-grouped">
            <p class="control is-expanded">
              <input
                v-model="field.src_format" class="input is-small" type="text"
                placeholder="Source Format"
              >
            </p>
            <p class="control is-expanded">
              <input
                v-model="field.output_format" class="input is-small" type="text"
                placeholder="Output Format"
              >
            </p>
            <p class="control">
              <span style="min-width: 3.5rem; display: inline-block;" />
            </p>
          </div>
        </template>

        <template v-if="field.definition && field.definition.is_mapping">
          <label class="label is-small is-uppercase">Aliases</label>
          <div class="field is-grouped">
            <p class="control is-expanded">
              <input
                v-model="mappingValue.key" class="input is-small" type="text"
                :placeholder="$t('key')"
              >
            </p>
            <p class="control is-expanded">
              <input
                v-model="mappingValue.value" class="input is-small" type="text"
                :placeholder="$t('value')"
              >
            </p>
            <p class="control">
              <a class="button is-info is-small" style="min-width: 3.5rem" @click.prevent="addMappingValue">{{ $t('add') }}</a>
            </p>
          </div>
          <div v-for="(v, index) in field.definition.mapping_values" :key="v.key" class="field">
            <div class="field is-grouped">
              <p class="control is-expanded">
                <input
                  v-model="v.key" class="input is-small" type="text"
                  :placeholder="$t('key')"
                >
              </p>
              <p class="control is-expanded">
                <input
                  v-model="v.value" class="input is-small" type="text"
                  :placeholder="$t('value')"
                >
              </p>
              <p class="control">
                <a class="button is-danger is-small" style="min-width: 3.5rem" @click.prevent="removeMappingValue(index)"><octicon :icon="x" /></a>
              </p>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>
<script>
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'
import { x } from 'octicons-vue'

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mappingValue: {},
      temp: null
    }
  },
  computed: {
    x () {
      return x
    }
  },
  watch: {
    editing (val) {
      if (val) {
        this.temp = JSON.parse(JSON.stringify(this.field))
        return
      }

      this.temp = null
    }
  },
  methods: {
    edit () {
      this.$emit('edit')
    },
    cancel () {
      this.$emit('cancel', JSON.parse(JSON.stringify(this.temp)))
    },
    toggleNumber () {
      if (this.readonly && !this.editing) {
        return
      }

      this.field.is_number = !this.field.is_number
    },
    toggleTimestamp () {
      if (this.readonly && !this.editing) {
        return
      }

      this.field.is_timestamp = !this.field.is_timestamp
    },
    toggleMapping () {
      if (this.readonly && !this.editing) {
        return
      }

      if (!this.field.definition) {
        return
      }

      this.field.definition.is_mapping = !this.field.definition.is_mapping
    },
    addMappingValue () {
      if (!this.field.definition) {
        this.field.definition = {}
      }

      if (!this.field.definition.mapping_values) {
        this.field.definition.mapping_values = []
      }

      if (!this.mappingValue.key) {
        return
      }

      this.field.definition.mapping_values.push(this.mappingValue)
      this.mappingValue = {}
    },
    removeMappingValue (idx) {
      if (!this.field.definition) {
        this.field.definition = {}
      }

      if (!this.field.definition.mapping_values) {
        this.field.definition.mapping_values = []
      }

      this.field.definition.mapping_values.splice(idx, 1)
    }
  }
}
</script>
